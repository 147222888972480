import React, { Component } from "react";

import { Row, Col, CardBody, Input } from "reactstrap";

import { Line } from "rc-progress";

import CguContent from "./cgucontent";

class Cgu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkOne: false,
      checkTwo: false,
      checkThree: false,
      error: null,
    };
  }

  verify = () => {
    if (!this.state.checkOne || !this.state.checkTwo || !this.state.checkThree) {
      this.setState({ error: "Please agree conditions to start onboarding" });
    } else {
      this.setState({ error: null });
      this.props.handleChangeView("company_info");
    }
  };

  render() {
    return (
      <CardBody>
        <div>
          <h5>1. General terms and conditions of use</h5>
          <Line percent={10} strokeWidth={2} strokeColor="#2eecc7" />
          <Row className="mt-4">
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-check-circle-outline text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-12 align-self-end">
              <p>
                By signing this agreement, you agree that the information you
                provide is trustful. You agree to abide by the terms of the
                agreement below.
              </p>
              <CguContent />
              <p>
                <strong>
                  <a
                    href="https://salvus-sceme-cgu.s3.eu-west-3.amazonaws.com/GTCU+SCEME.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download terms
                  </a>
                </strong>
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-2 col-md-2">
              <Input
                type="checkbox"
                className="mx-4"
                checked={this.state.checkOne}
                onChange={() =>
                  this.setState({ checkOne: !this.state.checkOne })
                }
              />
            </Col>
            <Col className="col-10 mt-1">
              <p>I agree to the SC-PaaS Platform GTCU</p>
            </Col>
            <Col className="col-2 col-md-2">
              <Input
                type="checkbox"
                className="mx-4"
                checked={this.state.checkTwo}
                onChange={() =>
                  this.setState({ checkTwo: !this.state.checkTwo })
                }
              />
            </Col>
            <Col className="col-10 mt-1">
              <p>
                I agree to{" "}
                <strong>
                  <a
                    href={"https://schuman.io/terms-and-conditions/"}
                    target="_blank"
                    rel="noreferrer"
                    className="text-info"
                  >
                    EUROP Terms
                  </a>
                </strong>
              </p>
            </Col>
            <Col className="col-2 col-md-2">
              <Input
                type="checkbox"
                className="mx-4"
                checked={this.state.checkThree}
                onChange={() =>
                  this.setState({ checkThree: !this.state.checkThree })
                }
              />
            </Col>
            <Col className="col-10 mt-1">
              <p>
                I confirm that I am not a resident of any of the{" "}
                <strong>
                  <a
                    href={"https://schuman.io/prohibited-countries/"}
                    target="_blank"
                    rel="noreferrer"
                    className="text-info"
                  >
                    restricted countries
                  </a>
                </strong>
              </p>
            </Col>
          </Row>
          {this.state.error && (
            <p className="my-1 text-danger">
              <strong>{this.state.error}</strong>
            </p>
          )}
          <Row className="mt-3">
            <Col className="col-4">
              <div
                className="btn btn-secondary btn-block waves-effect waves-light"
                onClick={() => this.props.handleChangeView("welcome")}
              >
                {"<"} Back
              </div>
            </Col>
            <Col className="col-8">
              <div
                className="btn btn-primary btn-block waves-effect waves-light"
                onClick={this.verify}
              >
                Agree conditions
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    );
  }
}

export default Cgu;
