import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Media,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "reactstrap";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { clientRequests, clientCancelRequest } from "../../helpers/api";
import SVGLogo from "../../components/Common/SVGLogo.js";

import { connect } from "react-redux";

import CSV from "../../components/Common/CSV";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      pendingRequests: 0,
      readyRequests: 0,
      volume: 0,
      cashflow: 0,
      loading: false,
      error: null,
      success: null,
      displayDesc: null,
      ethereumReserve: "-",
      polygonReserve: "-",
      transactions: {
        columns: [
          {
            label: "Reference ID",
            field: "reference",
            sort: "asc",
            width: 136,
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 136,
          },
          {
            label: "Date",
            field: "date",
            sort: "desc",
            width: 136,
          },
          {
            label: "EUROP amount",
            field: "amount",
            sort: "asc",
            width: 160,
          },
          {
            label: "Commission",
            field: "commission",
            sort: "asc",
            width: 160,
          },
          {
            label: "Chain fees",
            field: "gasfee",
            width: 160,
          },
          {
            label: "Chain",
            field: "chain",
            sort: "asc",
            width: 234,
          },
          {
            label: "Wallet",
            field: "wallet",
            sort: "asc",
            width: 234,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
      csvRows: [],
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  cancelPendingRequest = (reference) => {
    this.setState({ error: null, loading: true });
    this._asyncRequest = clientCancelRequest(reference)
      .then((res) => {
        if (res.status === "SUCCESS") {
          window.location.reload();
        } else {
          this.setState({ error: res.error, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString(), loading: false });
      });
  };

  componentDidMount = () => {
    this._asyncRequest = clientRequests()
      .then((res) => {
        if (res.status === "SUCCESS") {
          let volume = 0,
            cashflow = 0,
            pendingRequests = 0,
            readyRequests = 0,
            rows = [],
            csvRows = [];
          res.data.enrichedRequests.forEach((entry) => {
            if (entry.request.status === "VALIDATED") {
              volume = volume + entry.request.amount;
              if (entry.request.action === "PURCHASE")
                cashflow = cashflow + entry.request.amount;
              else cashflow = cashflow - entry.request.amount;
            }
            if (entry.request.status === "PENDING") pendingRequests++;
            else if (entry.request.status === "READY") readyRequests++;
            rows.push({
              reference: entry.request.processingReference
                ? `${entry.request.reference} updated from ${entry.request.processingReference}`
                : entry.request.previousReference
                ? `${entry.request.reference} updated from ${entry.request.previousReference}`
                : entry.request.reference,
              operation: (
                <>
                  {entry.request.action === "SALE" ? (
                    <span className="badge badge-dark font-size-10">
                      Sell / Cash-out
                    </span>
                  ) : (
                    <span className="badge badge-light font-size-10">
                      Purchase
                    </span>
                  )}
                  {entry.request.description && (
                    <i
                      role="button"
                      onClick={() => {
                        this.setState({
                          displayDesc: entry.request.description,
                        });
                      }}
                      className="mdi mdi-information-outline font-size-14 float-right text-primary "
                    ></i>
                  )}
                  {entry.request.action === "PURCHASE" && (
                    <span className="text-xs d-block">
                      Send to {entry.client.receivingBank}
                    </span>
                  )}
                  {entry.request.action === "SALE" && (
                    <>
                      {entry.request.chain === "polygon" ? (
                        <span className="text-xs d-block">
                          Send to POL:{res.data.polygon}
                        </span>
                      ) : (
                        <span className="text-xs d-block">
                          Send to ETH:{res.data.ethereum}
                        </span>
                      )}
                    </>
                  )}
                </>
              ),
              date: `${new Date(
                entry.request.createdAt
              ).toLocaleDateString()} ${new Date(
                entry.request.createdAt
              ).toLocaleTimeString()}`,
              amount:
                entry.request.amount &&
                entry.request.amount.toLocaleString() + " EUROP",
              commission: entry.request.commission
                ? entry.request.commission.toLocaleString() + " EUR"
                : "0 EUR",
              gasfee: entry.request.gasfee
                ? entry.request.gasfee.toLocaleString() + " EUR"
                : "0 EUR",
              chain: entry.request.chain,
              wallet: entry.request.publicKeyHash,
              status: (
                <>
                  {entry.request.status === "PENDING" ? (
                    <>
                      <span className="badge badge-warning font-size-10">
                        PENDING
                      </span>
                      <small className="d-block mb-2">
                        {entry.request.action === "PURCHASE"
                          ? "Awaiting wire transfer"
                          : "Awaiting EUROP transfer to Reserve"}
                      </small>
                      <span
                        className="badge badge-dark font-size-10 p-1 mr-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (window.confirm("Cancel request?")) {
                            this.cancelPendingRequest(entry.request.reference);
                          }
                        }}
                      >
                        <i className="mdi mdi-close"></i> Cancel
                      </span>
                    </>
                  ) : entry.request.status === "READY" ? (
                    <>
                      <span className="badge badge-pink font-size-10">
                        READY
                      </span>
                      <br />
                      <small>
                        {entry.request.action === "PURCHASE" ? (
                          <>
                            <span className="d-block mb-2">
                              Awaiting{" "}
                              <strong>{`r${entry.request.reference}`}</strong>{" "}
                              validation to transfer funds to <br />{" "}
                              <strong>{entry.request.publicKeyHash}</strong>
                            </span>
                          </>
                        ) : (
                          <>
                            <span>Awaiting € funds transfer to client</span>
                          </>
                        )}
                      </small>
                    </>
                  ) : entry.request.status === "VALIDATED" ? (
                    <>
                      <span className="badge badge-success font-size-10">
                        VALIDATED
                      </span>
                      <br />
                      <small>
                        {entry.request.action === "PURCHASE" ? (
                          <span>
                            EUROP funds transfered to <br />
                            <strong>{entry.request.publicKeyHash}</strong>
                          </span>
                        ) : (
                          <span>€ funds transferred</span>
                        )}
                      </small>
                    </>
                  ) : entry.request.status === "REJECTED" ? (
                    <>
                      <span className="badge badge-danger font-size-10">
                        REJECTED
                      </span>
                    </>
                  ) : entry.request.status === "CANCELLED" ? (
                    <>
                      <span className="badge badge-dark font-size-10">
                        CANCELLED
                      </span>
                    </>
                  ) : (
                    <span className="badge badge-danger font-size-10">
                      {entry.request.status}
                    </span>
                  )}
                </>
              ),
            });
            csvRows.push({
              reference: entry.request.reference,
              operation: entry.request.action,
              date: entry.request.createdAt,
              amount: entry.request.amount,
              commission: entry.request.commission
                ? entry.request.commission.toLocaleString()
                : 0,
              gasfee: entry.request.gasfee
                ? entry.request.gasfee.toLocaleString()
                : 0,
              chain: entry.request.chain,
              wallet: entry.request.publicKeyHash,
              status: entry.request.status,
            });
          });
          this.setState({
            transactions: {
              columns: [
                {
                  label: "Reference ID",
                  field: "reference",
                  sort: "asc",
                  width: 136,
                },
                {
                  label: "Operation",
                  field: "operation",
                  sort: "asc",
                  width: 136,
                },
                {
                  label: "Date",
                  field: "date",
                  sort: "desc",
                  width: 136,
                },
                {
                  label: "EUROP amount",
                  field: "amount",
                  width: 160,
                },
                {
                  label: "Commission",
                  field: "commission",
                  width: 160,
                },
                {
                  label: "Chain fees",
                  field: "gasfee",
                  width: 160,
                },
                {
                  label: "Chain",
                  field: "chain",
                  sort: "asc",
                  width: 234,
                },
                {
                  label: "Wallet",
                  field: "wallet",
                  sort: "asc",
                  width: 234,
                },
                {
                  label: "Status",
                  field: "status",
                  sort: "asc",
                  width: 100,
                },
              ],
              rows: rows.sort((a, b) => new Date(b.date) - new Date(a.date)),
            },
            loading: false,
            csvRows: csvRows,
            volume: volume,
            cashflow: cashflow,
            pendingRequests: pendingRequests,
            readyRequests: readyRequests,
            ethereumReserve: res.data.ethereum,
            polygonReserve: res.data.polygon,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Request" breadcrumbItem="All requests" />
            {!this.state.loading && (
              <>
                <Modal
                  isOpen={this.state.displayDesc}
                  toggle={() => this.setState({ displayDesc: null })}
                >
                  <div className="modal-header text-muted">Description</div>
                  <div className="modal-body">{this.state.displayDesc}</div>
                </Modal>
                {this.state.moreActions}
                {this.state.error && (
                  <p className="badge badge-danger font-size-12">
                    {this.state.error}
                  </p>
                )}
                {this.state.success && (
                  <p className="badge badge-success font-size-12">
                    Processing with operation ID {this.state.success}. Please
                    wait...
                  </p>
                )}
                <Row>
                  <Col md="3">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              Volume
                            </p>
                            <h4 className="mb-0">
                              <SVGLogo /> {this.state.volume.toLocaleString()}
                            </h4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              Provision
                            </p>
                            <h4 className="mb-0">
                              <SVGLogo /> {this.state.cashflow.toLocaleString()}
                            </h4>
                          </Media>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card className="mini-stats-wid bg-warning">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-dark font-weight-medium">
                              Pending requests
                            </p>
                            <h4 className="mb-0">
                              {this.state.pendingRequests}
                            </h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                            <span className="avatar-title bg-white">
                              <i className="bx bx-hourglass font-size-24 text-dark"></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card className="mini-stats-wid bg-pink">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-white font-weight-medium">
                              Ready for processing
                            </p>
                            <h4 className="text-white mb-0">
                              {this.state.readyRequests}
                            </h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                            <span className="avatar-title bg-white">
                              <i className="bx bx-check font-size-24 text-pink"></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="p-3"
                        >
                          <TabPane tabId="1" id="all-order">
                            <MDBDataTable
                              responsive
                              bordered
                              data={this.state.transactions}
                              order={["date", "desc"]}
                              className="mt-1 font-size-11"
                              striped
                              hover
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading{" "}
                </Link>
              </div>
            ) : (
              <FormGroup>
                <Row className="mr-3">
                  <Col sm="12">
                    <CSV
                      transactions={this.state.transactions}
                      csvRows={this.state.csvRows}
                      name="transactions"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Listing);
